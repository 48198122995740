import React from 'react';
import { Blank } from 'grommet-icons';

const IconCheck = (props) => (
  <Blank
    viewBox="0 0 21 15"
    aria-hidden="true"
    focusable="false"
    role="presentation"
    {...props}
  >
    <title>Icon check</title>
    <path
      d="M19.0488.3325l1.4141,1.4141L7.5415,14.6675.5371,7.6636,1.9512,6.25l5.59,5.59Z"
      fill="#fff"
    />
  </Blank>
);

export default IconCheck;
